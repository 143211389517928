<template>
  <div>
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="success" @click="download">导出记录</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #giftBagName>
        <el-table-column label="礼包名称"  align="center">
          <template #default="scoped">
            {{ scoped.row.giftBagName || scoped.row.goodsName }}
          </template>
        </el-table-column>
      </template>
      <template #action>
        <el-table-column label="操作" width="230" align="center">
          <template #default="scoped">
            <el-button
              type="primary"
              size="small"
              @click="editItem(scoped.row)"
            >
              修改状态
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessage } from 'element-plus'
import {
  giftQueryPage,
  giftExport,
  giftUpdate
} from '@/api/activityAwardRecord'
import { constantQuery } from '@/api/user'
const initData = reactive({
  formLabels: [
    {
      label: 'keywords',
      title: '关键字',
      placeholder: '请输入关键字以搜索'
    },
    {
      label: 'way',
      title: '兑换方式',
      placeholder: '请选择',
      type: 'options',
      options: []
    },
    {
      label: 'status',
      title: '发货状态',
      placeholder: '请选择',
      type: 'options',
      options: []
    }
  ],
  fromData: {
    // 表单数据
    keywords: '',
    way: '',
    status: ''
  },
  formColumn: 4,
  labelWidth: '90px',
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    {
      index: true,
      indexMethod(index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { slot: 'giftBagName' },
    { prop: 'exchangeTypeName', label: '兑换方式' },
    { prop: 'userMobile', label: '用户手机号' },
    { prop: 'phone', label: '收货人手机号' },
    { prop: 'receiver', label: '收货人' },
    { prop: 'region', label: '发货省市' },
    { prop: 'detailAddress', label: '发货地址明细' },
    { prop: 'stateName', label: '发货状态' },
    { prop: 'exchangeTime', label: '兑换时间' },
    { slot: 'action' }
  ]
})

onMounted(() => {
  getWayList()
  getDataList()
})

const getWayList = () => {
  constantQuery({ className: 'ExchangeType' }).then(({ data: res }) => {
    if (res.code == 200) {
      const list = res.data.map(item => {
        return {
          label: item.value,
          value: item.code
        }
      })
      formLabels.value[1].options = list
    } else {
      ElMessage.error(res.msg)
    }
  })

  constantQuery({ className: 'ExchangeState' }).then(({ data: res }) => {
    if (res.code == 200) {
      const list = res.data.map(item => {
        return {
          label: item.value,
          value: item.code
        }
      })
      formLabels.value[2].options = list
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keywords: '',
    way: '',
    status: ''
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 修改状态
const editItem = row => {
  giftUpdate({ id: row.id }).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      ElMessage.success(res.msg)
      getDataList()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 导出记录
const download = () => {
  const data = {}
  if (fromData.value.keywords !== '') {
    data.word = fromData.value.keywords
  }
  if (fromData.value.way !== '') {
    data.exchangeType = fromData.value.way
  }
  if (fromData.value.status !== '') {
    data.state = fromData.value.status
  }
  giftExport(data).then(res => {
    let url = window.URL.createObjectURL(new Blob([res.data]))
    let a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.setAttribute('download', `活动兑奖记录-${dateTime()}.xls`)
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(a.href)
    document.body.removeChild(a)
  })
}

const dateTime = time => {
  let dateday = ''
  if (time) {
    dateday = new Date(time)
  } else {
    dateday = new Date()
  }
  let year = dateday.getFullYear()
  let getMonth = dateday.getMonth() + 1
  let getDay = dateday.getDate()
  if (getMonth < 10) {
    getMonth
  }
  return `${year}-${getMonth < 10 ? '0' + getMonth : getMonth}-${
    getDay < 10 ? '0' + getDay : getDay
  }`
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.keywords !== '') {
    data.word = fromData.value.keywords
  }
  if (fromData.value.way !== '') {
    data.exchangeType = fromData.value.way
  }
  if (fromData.value.status !== '') {
    data.state = fromData.value.status
  }
  giftQueryPage(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth
} = toRefs(initData)
</script>
<style lang="scss" scoped>
.writeoff_box {
  display: flex;
  justify-content: center;
  align-items: center;
  .dialog_input {
    width: 50%;
  }
}
.remark_box {
  align-items: flex-start;
}
</style>
