import request from '../Axios'

// 列表
export const giftQueryPage = data => {
  return request({
    url: '/exchange/gift/queryPage',
    method: 'get',
    params: data
  })
}

// 导出记录
export const giftExport = data => {
  return request({
    url: '/exchange/gift/export',
    method: 'GET',
    params: data,
    responseType: 'blob'
  })
}


// 修改状态
export const giftUpdate = params => {
  return request({
    url: `/exchange/gift/update/${params.id}`,
    method: 'put'
  })
}